import React, { useEffect } from "react"
import SEO from "../components/Seo/Seo"

import { navigate } from "gatsby"

const homeMetadata = {
  Title: "Flipbase - Integrated video technology for recruitment",
  Keywords: [
    "Flipbase",
    "integrated video recruitment",
    "video for recruitment",
  ],
  Description:
    "Flipbase is a software company, and we offer integrated asynchronous video communication for recruitment professionals. Check out our new website for more information.",
  Image: "/seo-image-general.png",
}

export default () => {
  useEffect(() => {
    navigate("/introduction")
  }, [])
  return (
    <SEO
      title={homeMetadata.Title}
      description={homeMetadata.Description}
      image={homeMetadata.Image}
      keywords={homeMetadata.Keywords}
    />
  )
}
